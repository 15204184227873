<template>
  <div>
    {{ text }}
    <div v-if="error">
      <div>{{ $t("sign.login.resend") }}</div>
      <button @click.prevent="submit" class="vca-button">
        {{ $t("sign.login.new_token") }}
      </button>
      <button @click.prevent="login" class="vca-button-small">
        {{ $t("sign.login.title") }}
      </button>
    </div>
    <vca-card v-if="mail">
      <NewToken />
    </vca-card>
  </div>
</template>
<script>
import NewToken from "@/components/NewToken";
export default {
  name: "Confirm",
  props: {
    code: {
      type: String,
      default: "",
    },
  },
  components: { NewToken },
  data() {
    return {
      mail: false,
      error: false,
      text: "",
    };
  },
  methods: {
    submit() {
      this.mail = true;
    },
    login() {
      this.$router.push({ path: "/login", query: { scope: this.scope } });
    },
  },
  scope() {
    return this.$store.state.scope ? this.$store.state.scope : "community";
  },
  mounted() {
    this.$store.commit("register/confirmation/code", this.code);
    this.$store
      .dispatch({ type: "register/confirmation/submit" })
      .then(() => {
        var that = this;
        setTimeout(function () {
          /*if (data.data.message === "no_token") {
                        // TODO get scope from data.scope
                        that.$router.push({name: "Login", query: {scope: 'move4water', msg: data.message, source: 'confirm'}})
                    } else if(data.payload.message === "has_token") {
                        window.location = data.payload.redirect_url + "?code=" + data.payload.code
                    }*/
          that.login();
        }, 3000);
      })
      .catch(() => {
        this.error = true;
      });
  },
};
</script>
